import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Services from "../components/Services"
import Jobs from "../components/Jobs"
import Projects from "../components/Projects"
import Blogs from "../components/Blogs"
import SEO from "../components/SEO"

export default ({ data }) => {
  const {
    allContentfulProjects: { nodes: projects },
    allContentfulBlog: { nodes: blogs },
  } = data

  return (
    <Layout>
      <SEO title="IT Generalist, Software Developer" description="This is my home page" />
      <Hero />
      <Services />
      <Jobs />
      <Projects projects={projects} title="My projects" showLink />
      <Blogs blogs={blogs} title="My latest articles" showLink />
    </Layout>
  )
}
export const query = graphql`
  {
    allContentfulProjects(filter: { featured: { eq: true } }) {
      nodes {
        title
        featured
        status
        description {
          childMarkdownRemark {
            html
          }
        }
        github
        url
        stack
        image {
          gatsbyImage(width: 1100)
        }
      }
    }
    
    allContentfulBlog(sort: { fields: created, order: DESC }, limit: 6) {
      
        nodes {
          id
          slug
          content {
            content
          }
          title
          desc
          created
          category
          image {
            gatsbyImage(width: 500)
          }
        }
      
    }
 }
`
